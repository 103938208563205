<template>
  <div class="login">
    <amplify-authenticator>
      <!-- The rest of your app code -->
      <!-- https://docs.amplify.aws/ui/auth/authenticator/q/framework/vue#customization -->
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <amplify-sign-up slot="sign-up"
        :formFields="formFields"
      ></amplify-sign-up>
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <amplify-sign-in slot="sign-in"></amplify-sign-in>
      <amplify-sign-out></amplify-sign-out>
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth, I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

export default {
  name: 'Login',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      console.log('authState: ' + authState);
      console.log('authData: ' + authData);

      // ログインでトップページに遷移
      if (authState === 'signedin') {
        this.$router.push({path: '/'});
      }

      Auth.currentAuthenticatedUser()
      .then(user => console.log(user))
      .catch(err => console.log(err));
    })
  },
  data() {
    return {
      formFields: [
        {
          type: 'username',
          required: true,
        },
        {
          type: 'email',
          required: true,
        },
        {
          type: 'password',
          label: 'パスワード * （ 8 文字以上）',
          placeholder: '8 文字以上',
          inputProps: { minlength: 8 },
          required: true,
        },
        {
          type: 'gender',
          label: '性別',
          placeholder: 'male, female, other',
          inputProps: { pattern: 'male|female|other' },
          required: true,
        },
        {
          type: 'custom:age',
          label: '年齢',
          placeholder: '10, 20, 30, ...',
          hint: '20代であれば「20」、30代であれば「30」と入力してください',
          inputProps: { type: 'number', min: 0, max: 120, step: 10 },
          required: true,
        },
      ],
    }
  },
  mounted() {
    I18n.putVocabulariesForLanguage("ja", {
      [Translations.CREATE_ACCOUNT_TEXT]: 'アカウントを作成',
      [Translations.USERNAME_LABEL]: 'ユーザー名 *',
      [Translations.USERNAME_PLACEHOLDER]: 'ユーザー名を入力',
      [Translations.EMAIL_LABEL]: 'メールアドレス *', 
      [Translations.EMAIL_PLACEHOLDER]: 'メールアドレスを入力',
      [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れの場合',
      [Translations.PASSWORD_LABEL]: 'パスワード *',
      [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力', 
      [Translations.RESET_PASSWORD_TEXT]: 'パスワードをリセット',
      [Translations.SIGN_IN_ACTION]: 'ログイン',
      [Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
      [Translations.NO_ACCOUNT_TEXT]: ' ',
      [Translations.SIGN_UP_HEADER_TEXT]: 'アカウントを作成',
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'アカウントがある場合',
      [Translations.SIGN_IN_TEXT]: 'ログイン',
      [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'アカウントを作成',
      [Translations.RESET_YOUR_PASSWORD]: 'パスワードリセット',
      [Translations.BACK_TO_SIGN_IN]: 'ログインに戻る',
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'メールアドレス確認',
      [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: '認証コード',
      [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'コードが届いていませんか？',
      [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: '再送信',
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'コードを入力',
      [Translations.CONFIRM]: 'コードを送信',
      [Translations.SEND_CODE]: 'コードを送信',
      [Translations.CODE_LABEL]: '認証コード',
      [Translations.CODE_PLACEHOLDER]: 'コードを入力',
      [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
      [Translations.NEW_PASSWORD_PLACEHOLDER]: '新しいパスワードを入力',
      [Translations.SUBMIT]: '送信',
    });
  }
}
</script>

<style scoped>

</style>